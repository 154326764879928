import React from "react";

import { Block } from "../../components";

import AboutPartieImg from "../../images/about-partie.svg";
import TrackedLink from "../trackers/link-tracker";

import "./about-partie-block.scss";

const AboutPartieBlock = () => {
  return (
    <Block className="about-partie-block" lazy>
      <div className="partie-block__column">
        <img src={AboutPartieImg} alt="Partie Team" loading="lazy" />
      </div>
      <div className="partie-block__column">
        <h3 className="partie-block__title">About partie</h3>
        <p className="partie-block__description">
          Quit waiting and start playing — Partie will help you find the perfect
          squad to accomplish your in-game goals, unlock achievements and even
          earn revenue. Sign up today and let the games begin.
        </p>
        <div className="partie-block__cta">
          <TrackedLink type="btn btn--accent" to="/features" label="Partie Matchmaking Features" children="Learn More" />
        </div>
      </div>
    </Block>
  );
};

export default AboutPartieBlock;
