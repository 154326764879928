import React from "react";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const TrackedLink = ({ type, to, children, target, rel, label }) => {
  if (to && to.startsWith("/")) {
    return (
      <Link className={type} to={to} >
        {children}
      </Link>
    )
  }

  return (
    <OutboundLink className={type} href={to} target={target} rel={rel} aria-label={label} >
      {children}
    </OutboundLink>
  )
}

export default TrackedLink;